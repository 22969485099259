
import { defineComponent, PropType, toRef, ref } from 'vue'
import { useLoadingState } from './use-loading-state'
import { DeviationSummaryPeriod } from '@dview/shared/models'
import { useAppInsights } from '@dview/shared/util'

export default defineComponent({
    props: {
        input: {
            type: Object as PropType<OverviewPeriodSelectionComponentInput>
        },
    },
    emits: [ 'period-select' ],
    setup(props, { emit }) {
        const { state } = useLoadingState(toRef(props, 'input'))
        const { trackEvent } = useAppInsights()
        
        const selectedPeriodText = ref('24 hours')
        const periodSelectorVisible = ref(false)
        const periodSelectorDomEvent = ref() // used to open the popover relative to the button triggering it

        function openPeriodSelector(event: Event) {
            periodSelectorDomEvent.value = event
            periodSelectorVisible.value = true
        }

        function selectPeriod(period: DeviationSummaryPeriod) {
            if (period === '24hours') {
                selectedPeriodText.value = '24 hours'
            } else if (period === 'weekly') {
                selectedPeriodText.value = '7 days'
            } else if (period === 'monthly') {
                selectedPeriodText.value = '30 days'
            }

            periodSelectorVisible.value = false

            trackPeriodSelectionEvent(period)
            emit('period-select', period)
        }

        function trackPeriodSelectionEvent(period: DeviationSummaryPeriod) {
            trackEvent({
                name: 'Overview -> Changed period',
                properties: {
                    period
                }
            })
        }

        return {
            state,
            selectedPeriodText,
            periodSelectorVisible,
            periodSelectorDomEvent,
            openPeriodSelector,
            selectPeriod
        }
    }
})

export interface OverviewPeriodSelectionComponentInput {
    openDeviationsTotal: number
}
