
import { defineComponent } from 'vue'
import { modalController } from '@ionic/vue'
import { chevronBackOutline } from 'ionicons/icons'
import { useOrganizationAreas } from '@dview/logic'
import DeviationsList from '../shared/deviations-list/DeviationsList.vue'

export default defineComponent({
    components: {
        DeviationsList
    },
    setup() {
        const { activeAreaSelected } = useOrganizationAreas()

        function closeModal() {
            modalController.dismiss()
        }

        return {
            activeAreaSelected,
            closeModal,
            backIcon: chevronBackOutline
        }
    }
})
