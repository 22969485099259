
import { defineComponent, toRef } from 'vue'
import { DeviationClassification, DeviationType } from '@dview/shared/models'
import DeviationClassificationIcon from '../DeviationClassificationIcon.vue'
import { useLoadingState } from '../use-loading-state'
import { useRouter } from 'vue-router'
import { DViewPage } from '@dview/pages/pagenames'
import { useDeviationsFilterConfig } from '@dview/logic'
import { useAppInsights } from '@dview/shared/util'

export default defineComponent ({
    components: {
        DeviationClassificationIcon
    },
    props: {
        value: {
            type: Number,
        },
    },
    
    setup(props) {
        const router = useRouter()
        const { state } = useLoadingState(toRef(props, 'value'))
        const { setFilterKey, setSortingDirection, setSortingKey, markFilterAppliedByApp } = useDeviationsFilterConfig(DeviationType.Open)
        const { trackEvent } = useAppInsights()

        function gotoOpenDeviations() {
            trackEvent({ name: 'Overview -> Clicked major deviations widget' })
            applyAutomaticFilter()
            router.push({ name: DViewPage.DeviationsOpen })
        }

        function applyAutomaticFilter() {
            setFilterKey('major')
            setSortingKey('date')
            setSortingDirection('descending')
            markFilterAppliedByApp()
        }

        return {
            state,
            gotoOpenDeviations,
            Classification: DeviationClassification
        }
    }
})
