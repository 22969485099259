
import { defineComponent, toRef } from 'vue'
import { useRouter } from 'vue-router'
import { DViewPage } from '@dview/pages/pagenames'
import { useLoadingState } from '../use-loading-state'
import { useDeviationsFilterConfig } from '@dview/logic'
import { DeviationType } from '@dview/shared/models'
import { useAppInsights } from '@dview/shared/util'

export default defineComponent ({
    props: {
        value: {
            type: Number,
        },
    },
    setup(props) {
        const router = useRouter()
        const { state } = useLoadingState(toRef(props, 'value'))
        const { setFilterKey, setSortingDirection, setSortingKey, markFilterAppliedByApp } = useDeviationsFilterConfig(DeviationType.HoC)
        const { trackEvent } = useAppInsights()

        function gotoHocRatings() {
            trackEvent({ name: 'Overview -> Clicked HoC widget' })
            applyShortcutFilter()
            router.push({ name: DViewPage.DeviationsHocRatings })
        }

        function applyShortcutFilter() {
            setFilterKey('hoc-low-rated')
            setSortingKey('date')
            setSortingDirection('descending')
            markFilterAppliedByApp()
        }

        return {
            state,
            gotoHocRatings
        }
    }
})
