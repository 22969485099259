import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_DeviationsNoFilterSelected = _resolveComponent("DeviationsNoFilterSelected")!
  const _component_DeviationsErrorMessage = _resolveComponent("DeviationsErrorMessage")!
  const _component_OverviewPeriodSelection = _resolveComponent("OverviewPeriodSelection")!
  const _component_DeviationBar = _resolveComponent("DeviationBar")!
  const _component_DeviationWidgets = _resolveComponent("DeviationWidgets")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_menu_button, { menu: "homemenu" })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, {
        class: _normalizeClass({ 'ion-padding': !_ctx.noAreaSelected })
      }, {
        default: _withCtx(() => [
          (_ctx.noAreaSelected)
            ? (_openBlock(), _createBlock(_component_DeviationsNoFilterSelected, { key: 0 }))
            : (_ctx.error)
              ? (_openBlock(), _createBlock(_component_DeviationsErrorMessage, { key: 1 }))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  _createVNode(_component_OverviewPeriodSelection, {
                    input: _ctx.filterSelectionInput,
                    onPeriodSelect: _ctx.onPeriodSelected
                  }, null, 8, ["input", "onPeriodSelect"]),
                  _createVNode(_component_DeviationBar, {
                    input: _ctx.deviationBarInput,
                    class: "deviation-bar"
                  }, null, 8, ["input"]),
                  _createVNode(_component_DeviationWidgets, { input: _ctx.deviationWidgetsInput }, null, 8, ["input"])
                ], 64))
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _: 1
  }))
}