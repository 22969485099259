import _ from 'lodash'
import { Ref, ref, watchEffect } from 'vue'

/** A temporary helper state to fix an Ionic 5 bug in relation to Vue <transition> elements. */
export function useLoadingState(props: Ref<any>) {
    const state = ref<LoadingState>('initing')

    watchEffect(() => {
        if (props.value != null && state.value === 'loading') {
            setTimeout(() => {
                state.value = 'loaded'
            }, _.random(100, 1000))
        }

        if (props.value == null && state.value === 'loaded') {
            state.value = 'loading'
        }
    })
    
    /**
     * The <transition appear> state will not work unless some amount of plentiful initial
     * delay is added to the first state change...Preeeeeeeeeeetty sucky!
     * (...this is fixed in Ionic 6 beta!)
     */
    function ionicBugfixAnimation() {
        setTimeout(() => {
            state.value = 'loading'
        }, 100);    
    }
    
    ionicBugfixAnimation()
    
    return {
        state
    }
}

type LoadingState = 'initing' | 'loading' | 'loaded'