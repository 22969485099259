
import { defineComponent, PropType } from 'vue'
import ClosedDeviationsWidget from './widgets/ClosedDeviationsWidget.vue'
import HocDeviationsWidget from './widgets/HocDeviationsWidget.vue'
import MajorDeviationsWidget from './widgets/MajorDeviationsWidget.vue'
import MinorDeviationsWidget from './widgets/MinorDeviationsWidget.vue'
import { DeviationSummaryPeriod } from '@dview/shared/models'

export default defineComponent({
    components: {
        ClosedDeviationsWidget,
        HocDeviationsWidget,
        MajorDeviationsWidget,
        MinorDeviationsWidget,
    },
    props: {
        input: {
            type: Object as PropType<DeviationWidgetsComponentInput>,
            default: null
        }
    },
})

export interface DeviationWidgetsComponentInput {
    closedDeviations: number
    hocRatings: number
    majorDeviations: number
    minorDeviations: number
    period: DeviationSummaryPeriod
}
