
import { defineComponent, computed, ref, watch } from 'vue'
import { onIonViewDidEnter } from '@ionic/vue'
import { useDeviationSummary, useOrganizationAreas } from '@dview/logic'
import { DeviationSummary, DeviationSummaryPeriod } from '@dview/shared/models'
import DeviationsNoFilterSelected from '../shared/DeviationsNoAreaSelected.vue'
import OverviewPeriodSelection, { OverviewPeriodSelectionComponentInput } from './OverviewPeriodSelection.vue'
import DeviationBar, { DeviationBarComponentInput } from './DeviationBar.vue'
import DeviationWidgets, { DeviationWidgetsComponentInput } from './DeviationWidgets.vue'
import DeviationsErrorMessage from '../shared/DeviationsErrorMessage.vue'

export default defineComponent({
    components: {
        DeviationBar,
        OverviewPeriodSelection,
        DeviationWidgets,
        DeviationsNoFilterSelected,
        DeviationsErrorMessage
    },
    setup() {
        const { activeAreasFilter, activeAreaSelected } = useOrganizationAreas()
        const { status, summary, load: loadDeviationSummary, refresh: refreshDeviationSummary } = useDeviationSummary()

        const selectedPeriod = ref<DeviationSummaryPeriod>('24hours')

        watch([activeAreasFilter, activeAreaSelected], values => {
            const filterSelected = values[1]
            if (filterSelected) {
                loadDeviationSummary(selectedPeriod.value)
            }
        }, { immediate: true })

        const filterSelectionInput = computed(() => {
            return generateOverviewPeriodSelectionInput(summary.value)
        })

        const deviationBarInput = computed(() => {
            if (status.value === 'loading') {
                return null!
            }
            return generateDeviationBarInput(summary.value)
        })

        const deviationWidgetsInput = computed(() => {
            if (status.value === 'loading') {
                return null!
            }
            return generateDeviationWidgetsInput(summary.value)
        })

        function generateOverviewPeriodSelectionInput(summary: DeviationSummary): OverviewPeriodSelectionComponentInput {
            if (summary) {
                return {
                    openDeviationsTotal: summary.openDeviations.currentTotal
                }
            } else {
                return {} as any
            }
        }

        function generateDeviationBarInput(summary: DeviationSummary): DeviationBarComponentInput {
            if (summary) {
                return {
                    openDeviations: {
                        total: summary.openDeviations.periodTotal,
                        major: summary.openDeviations.periodMajor,
                        minor: summary.openDeviations.periodMinor,
                        unclassified: summary.openDeviations.periodUnclassified
                    },
                    closedDeviations: {
                        total: summary.closedDeviations.periodTotal
                    },
                    period: selectedPeriod.value
                }
            } else {
                return null!
            }
        }

        function generateDeviationWidgetsInput(summary: DeviationSummary): DeviationWidgetsComponentInput {
            if (summary) {
                const getHocCount = (rating: number) => {
                    const count = (summary.hocRatings as any)['periodRating' + rating]
                    return count == null ? 0 : count
                }
                
                const lowRatedHocCount = getHocCount(0) + getHocCount(1) + getHocCount(2) + getHocCount(3) + getHocCount(4)

                return {
                    closedDeviations: summary.closedDeviations.periodTotal,
                    hocRatings: lowRatedHocCount,
                    majorDeviations: summary.openDeviations.periodMajor,
                    minorDeviations: summary.openDeviations.periodMinor,
                    period: selectedPeriod.value
                }
            } else {
                return {} as any
            }
        }

        function onPeriodSelected(period: DeviationSummaryPeriod) {
            selectedPeriod.value = period
            loadDeviationSummary(period)
        }

        onIonViewDidEnter(() => {
            refreshDeviationSummary()
        })

        return {
            error: computed(() => status.value === 'error'),
            noAreaSelected: computed(() => !activeAreaSelected.value),
            filterSelectionInput,
            deviationBarInput,
            deviationWidgetsInput,
            onPeriodSelected,
        }
    }
})
