
import { defineComponent, toRef } from 'vue'
import { modalController } from '@ionic/vue'
import { checkmarkCircle } from 'ionicons/icons'
import ClosedDeviationsModal from '../ClosedDeviationsModal.vue'
import { useLoadingState } from '../use-loading-state'
import { useAppInsights } from '@dview/shared/util'

export default defineComponent({
    props: {
        value: {
            type: Number,
        },
    },
    setup(props) {
        const { state } = useLoadingState(toRef(props, 'value'))
        const { trackEvent } = useAppInsights()

        async function showClosedDeviations() {
            trackEvent({ name: 'Overview -> Clicked closed deviations widget' })

            const modal = await modalController.create({
                component: ClosedDeviationsModal
            })

            modal.present()
        }

        return {
            state,
            showClosedDeviations,
            checkmarkCircle
        }
    }
})
