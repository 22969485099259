
import { defineComponent } from 'vue'
import { alertCircle, warning, helpCircle } from 'ionicons/icons'
import { DeviationClassification } from '@dview/shared/models'

export default defineComponent({
    props: {
        classification: {
            type: Number as () => DeviationClassification,
            required: true
        }
    },
    setup(props) {
        const iconData = getClassificationIconData(props.classification)

        return {
            iconData
        }
    }
})

function getClassificationIconData(classificationId: DeviationClassification) {
    const iconData = {
        icon: '',
        color: ''
    }

    if (classificationId === DeviationClassification.Unclassifed) {
        iconData.icon = helpCircle
        iconData.color = 'unclassified'
    } else if (classificationId === DeviationClassification.Major) {
        iconData.icon = alertCircle
        iconData.color = 'major'
    } else if (classificationId === DeviationClassification.Minor) {
        iconData.icon = warning
        iconData.color = 'minor'
    }

    return iconData
}
