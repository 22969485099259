
import { defineComponent, computed, PropType, toRef } from 'vue'
import { IonProgressBar } from '@ionic/vue'
import { DeviationClassification, DeviationSummaryPeriod } from '@dview/shared/models'
import DeviationClassificationIcon from './DeviationClassificationIcon.vue'
import { useLoadingState } from './use-loading-state'

export default defineComponent({
    components: {
        DeviationClassificationIcon,
        IonProgressBar
    },
    props: {
        input: Object as PropType<DeviationBarComponentInput>
    },
    setup(props) {
        const { state } = useLoadingState(toRef(props, 'input'))

        const bar = computed(() => calculateBarPercentages(props.input))
        const legend = computed(() => calculateLegend(props.input))
        const noDeviationsInPeriod = computed(() => {
            if (state.value !== 'loaded') {
                return false
            }

            if (!props.input) {
                return false
            }

            return props.input?.openDeviations.total + props.input?.closedDeviations.total === 0
        })

        function calculateBarPercentages(input?: DeviationBarComponentInput) {
            if (input == null) {
                return {
                    majorStyle: {},
                    minorStyle: {},
                    unclassifiedStyle: {},
                    closedStyle: {},
                    showDivider: false
                }
            }

            const { openDeviations, closedDeviations } = input
            const totalDeviations = openDeviations.total + closedDeviations.total

            const calculatePercentage = (partial: number) => Math.ceil((100 * partial) / totalDeviations)

            const major = calculatePercentage(openDeviations.major)
            const minor = calculatePercentage(openDeviations.minor)
            const unclassified = calculatePercentage(openDeviations.unclassified)
            const closed = 100 - (major + minor + unclassified) // reserve remainder of percentage space for closed deviations

            return {
                majorStyle: { width: `${major}%` },
                minorStyle: { width: `${minor}%` },
                unclassifiedStyle: { width: `${unclassified}%` },
                closedStyle: { width: `${closed}%` },
                showDivider: closed > 0
            }
        }

        function calculateLegend(input?: DeviationBarComponentInput) {
            if (input == null) {
                return {
                    visible: false,
                    totalOpen: 0,
                    totalClosed: 0,
                    major: 0,
                    minor: 0,
                    unclassified: 0
                }
            }

             return {
                visible: true,
                totalOpen: input.openDeviations.total,
                totalClosed: input.closedDeviations.total,
                major: input.openDeviations.major,
                minor: input.openDeviations.minor,
                unclassified: input.openDeviations.unclassified
            }
        }

        return {
            state,
            bar,
            legend,
            noDeviationsInPeriod,
            Classification: DeviationClassification,
        }
    }
})

export interface DeviationBarComponentInput {
    openDeviations: {
        total: number
        major: number
        minor: number
        unclassified: number
    }
    closedDeviations: {
        total: number
    }
    period: DeviationSummaryPeriod
}
